<template>
    <b-card >
      
        <div class="m-2">
            <b-row>
                <b-col
                    cols="12"
                    md="6"
                    class="d-flex align-items-center justify-content-start mb-1 mb-md-0"
                >
                    
                </b-col>
            </b-row>
        </div>
        <b-table
            ref="refGroupListTable"
            :items="fetchGroups"
            responsive
            :fields="tableColumns"
            primary-key="id"
            :sort-by.sync="sortBy"
            show-empty
            empty-text="No matching records found"
            :sort-desc.sync="isSortDirDesc"
            class="position-relative"
        >
            <template #head(action)>
                <feather-icon
                icon="TrendingUpIcon"
                class="mx-auto"
                />
            </template>
            <template #cell(action)="data">
                
                <b-button
                v-if="data.item.id > 1"
                v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                    variant="outline-primary"
                    @click="popupPermission(data.item)"

                >
              
                    
                    Permission
                </b-button>
            </template>
        </b-table>
        <div class="mx-2 mb-2">
            <b-row>

                <b-col
                cols="12"
                sm="6"
                class="d-flex align-items-center justify-content-center justify-content-sm-start"
                >
                <span class="text-muted">Showing {{ dataMeta.from }} to {{ dataMeta.to }} of {{ dataMeta.of }} entries</span>
                </b-col>
                <!-- Pagination -->
                <b-col
                cols="12"
                sm="6"
                class="d-flex align-items-center justify-content-center justify-content-sm-end"
                >

                <b-pagination
                    v-model="currentPage"
                    :total-rows="totalGroups"
                    :per-page="perPage"
                    first-number
                    last-number
                    class="mb-0 mt-1 mt-sm-0"
                    prev-class="prev-item"
                    next-class="next-item"
                >
                    <template #prev-text>
                    <feather-icon
                        icon="ChevronLeftIcon"
                        size="18"
                    />
                    </template>
                    <template #next-text>
                    <feather-icon
                        icon="ChevronRightIcon"
                        size="18"
                    />
                    </template>
                </b-pagination>

                </b-col>

            </b-row>
        </div>
        <b-modal
      id="modal-prevent-closing"
      ref="my-modal"
     
      size="xl"
      ok-title="Submit"
      cancel-variant="outline-secondary"
      v-model="modalShow"
     
      @hidden="resetModal"
      @ok="handleOk"
    >
    <template #modal-title>
      Permission {{ group.title }}
    </template>
      <form
        ref="form"
        @submit.stop.prevent="handleSubmit"
      >
      
        <b-table
          
          responsive
          :fields="tablePermissionColumns"
          :items="permissionsData"
        >
          <template #cell(module)="data">
            {{ data.item.module }}
          </template>
          <template #cell(permission)="data">
            <div class="d-flex">
              <span v-for="(itm, index) in data.item" :key="'obj-'+index" v-if="index !== 'module'">
              <b-form-checkbox class="mr-1" v-model="permissionsData[data.index][index]" >{{  t(index) }}</b-form-checkbox>
              
            </span>
            </div>
            
            
            
          </template>
        </b-table>
       
      </form>
      </b-modal>
    </b-card>
</template>
<script>
import {
  BCard, BRow, BCol, BFormInput, BButton, BTable, BMedia, BAvatar, BLink,
  BBadge, BDropdown, BDropdownItem, BPagination, BTooltip,VBModal,BFormGroup,BFormCheckbox
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import Ripple from 'vue-ripple-directive'
//api
import axios from '@axios'
import store from '@/store'
import useGroups from './useGroups'
import { onUnmounted } from '@vue/composition-api'
import permissionsStoreModule from './permissionsStoreModule'
import { VueGoodTable } from 'vue-good-table'
//import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import { useUtils as useI18nUtils } from '@core/libs/i18n'

export default{
    components: {
        BCard,
        BRow,
        BCol,
        BFormInput,
        BButton,
        BTable,
        BMedia,
        BAvatar,
        BLink,
        BBadge,
        BDropdown,
        BDropdownItem,
        BPagination,
        BTooltip,
        VBModal,
        BFormGroup,
        BFormCheckbox,
        vSelect,
        VueGoodTable
    },
    setup() {
      const { t } = useI18nUtils()

        const PREMISSIONS_GROUPS_STORE_MODULE_NAME = 'permissions-groups'
        if (!store.hasModule(PREMISSIONS_GROUPS_STORE_MODULE_NAME)) store.registerModule(PREMISSIONS_GROUPS_STORE_MODULE_NAME, permissionsStoreModule)

            // UnRegister on leave
            onUnmounted(() => {
            if (store.hasModule(PREMISSIONS_GROUPS_STORE_MODULE_NAME)) store.unregisterModule(PREMISSIONS_GROUPS_STORE_MODULE_NAME)
            })
    const permissionsSettingData = [
   
    ];
    const permissionsData = [
      {
        module: 'User',
        read: false,
        create: false,
        update: false,
        delete: false,
     
      },
      {
        module: 'Patients',
        read: false,
        create: false,
        update: false,
        delete: false,
        
      },
      
      {
        module: 'Queue',
        read: false,
       
        queue_examination:false,
        queue_services:false,
        queue_payments:false
       
        
      },
      {
        module: 'Checklists',
        read:false,
        category_read:false,
        advice_read:false,
      },
      {
        module: 'room',
        read:false,
        create: false,
        update: false,
        delete: false
      },
      {
        module:'appointment',
        read:false,
        create: false,
        update: false,
        delete: false,
        appointment_calendar: false
      },
      {
        module:'voucher',
        read:false,
        create: false,
        update: false,
        delete: false,
        category_read:false
      },
      {
        module:'course',
        read:false,
        create: false,
        update: false,
        delete: false,
        category_read:false
      },
      {
        module:'medicine',
        read:false,
        create: false,
        update: false,
        delete: false,
        category_read:false
      },
      {
        module: 'unit',
        read:false,
        create: false,
        update: false,
        delete: false
       
      },
      {
        module: 'warehouse',
        read:false,
        create: false,
        update: false,
        delete: false
       
      },
      {
        module: 'stock',
        read:false,
        create: false,
        update: false,
        delete: false
       
      },
      {
        module: 'report',
        read:false,
        manager:false
        
       
      },
      {
        module: 'Settings',
        general: false,
        permission: false
       
      },
      
      
      
    ]
        const {
            fetchGroups,
            tableColumns,
            perPage,
            currentPage,
            totalGroups,
            dataMeta,
            perPageOptions,
            searchQuery,
            sortBy,
            isSortDirDesc,
            refGroupListTable,
        
            statusFilter,
            tablePermissionColumns,
    
        
            refetchData,
        } = useGroups()
        return {
          t,
            fetchGroups,
            tableColumns,
            perPage,
            currentPage,
            totalGroups,
            dataMeta,
            perPageOptions,
            searchQuery,
            sortBy,
            isSortDirDesc,
            refGroupListTable,
        
            statusFilter,
        
          

            refetchData,
            permissionsData,
            permissionsSettingData,
            tablePermissionColumns
        }
        //if (!store.hasModule(INVOICE_APP_STORE_MODULE_NAME)) store.registerModule(INVOICE_APP_STORE_MODULE_NAME, invoiceStoreModule)
    },
    directives:{
        'b-modal': VBModal,
        Ripple

    },
    data: () => ({
        name: '',
        modalShow:false,
        nameState: null,
        submittedNames: [],
        group:{}
    }),
    methods: {
    popupPermission(group){
     
      let roles = group.roles;
        this.permissionsData = this.permissionsData.map(res=>{
         
          Object.keys(res).map((value,key) => {
            if(value != 'module'){
              res[value] = false;
            }
           
            
          });
        
          if(roles){
            let index = roles.findIndex(e => e.module === res.module);
            
            
          
            if(index > -1){
              Object.keys(res).map((value,key) => {
              
                if(value != 'module'){
                  res[value] = roles[index][value];
                }
                
              });
              
              //res.read = roles[index].read;
             /* res.read = roles[index].read;
              res.create = roles[index].create;
              res.update = roles[index].update;
              res.delete = roles[index].delete;*/

            }
            

          }
          
          
          return res;
        })
        this.permissionsSettingData = this.permissionsSettingData.map(res=>{
          res.permission = false;
          res.general = false;
        
          if(roles){
            let index = roles.findIndex(e => e.module === res.module);
            if(index > -1){
              res.permission = roles[index].permission;
              res.general = roles[index].general;
              

            }
            

          }
          
          
          return res;
        })
       
        this.group = group;
        console.log('group',this.group.title);
        //this.modalShow = true;
        this.$refs['my-modal'].toggle('#toggle-btn')
    },
    checkFormValidity() {
      const valid = this.$refs.form.checkValidity()
      this.nameState = valid
      return valid
    },
    resetModal() {
        this.group={}
    
    },
    handleOk(bvModalEvt) {
      // Prevent modal from closing
      bvModalEvt.preventDefault()
      // Trigger submit handler
      this.handleSubmit()
    },
    makeToast(variant = null,title,message) {
      this.$bvToast.toast(message, {
        title: title,
        variant,
        solid: true,
      })
    },
    async handleSubmit() {
      
      
      let merged = this.permissionsData.concat(this.permissionsSettingData);
      var data = JSON.stringify(merged);
      let res = await axios.put('groups/v1/updateroles/'+this.group.id,data);
     
      if(!res.data.success){
          
        this.makeToast('danger','Error 403',res.data.message);
      
        
      }else{
        this.makeToast('success','Update Permission','Done!');
        this.refetchData();
      }
      this.$refs['my-modal'].toggle('#toggle-btn');
      // Exit when the form isn't valid
      /*if (!this.checkFormValidity()) {
        return
      }
      // Push the name to submitted names
      this.submittedNames.push(this.name)*/
      // Hide the modal manually
     /*this.$nextTick(() => {
        this.$refs['my-modal'].toggle('#toggle-btn')
      })*/
    },
  },
}
</script>
